import React from 'react';
import styles from './banner-service.module.scss';

const BannerService = ({service}) => (
	<section className={`hero ${styles.banner}`}>
		<div className="hero-body">
			<div className="container has-margin-top-1">
                <h1 className="title is-uppercase">{service.name}</h1>
                <p className="subtitle">{service.summary}</p>
			</div>
		</div>
	</section>
);

export default BannerService;
