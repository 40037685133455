import React from 'react';

import MarkdownView from "react-showdown";
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import BannerService from '../components/banner-service';
import '../styles/markdown.scss';
import styles from './service.module.scss';


export default function ServicePage({ data }) {

    return (
        <Layout hideNavbar={false}>
            <BannerService service={data.strapiServices}></BannerService>
            <section className="markdown">
                <div className={`container ${styles.container}`}>
                    <MarkdownView markdown={data.strapiServices.description} options={{ tables: true, emoji: true }}></MarkdownView>            
                </div>
            </section>
        </Layout>
    );
}

export const pageQuery = graphql`
query ServiceTemplate($slug: String!) {
    strapiServices(slug: {eq: $slug}) {
		name
		description
		summary
		slug
        image {
            childImageSharp {
                fluid(maxWidth: 600, maxHeight: 200, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }                                        
    }
}
`